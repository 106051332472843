<template>
  <div class="w-100 flex-fill flex-column d-flex">
    <input
      type="text"
      class="position-fixed top-0 left-0"
      style="display: none; color: #fff; z-index: 99999; opacity: 0;"
      id="userDetailSameIpCopy"
    />
    <div class="d-flex">
      <div class="mb-3 mr-2 alert alert-default p-1 pl-2 pr-2 flex-fill">
        <div v-if="deviceResolution !== null">
          <span>
            <i>Device's Resolution: {{deviceResolution}}</i>
          </span>
          <span v-if="registerBrowserDetail !== null">
            |
            <span>
              <i>{{registerBrowserDetail.userAgent}}</i>
            </span>
          </span>
        </div>
      </div>
      <div class="mb-3 d-flex align-content-end justify-content-end align-items-center">
        <div class="input-group input-group-sm mr-2" style="width: 13rem;">
          <input
            type="text"
            name="table_search"
            class="form-control float-right"
            placeholder="Search"
            v-model="elements.querySearch"
            @input="onSearchInput(true)"
            v-on:keyup.enter="onSearchInput(false)"
          />
          <div class="input-group-append">
            <button type="submit" class="btn btn-default" @click="onSearchInput(false)">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-primary btn-sm mr-2 text-nowrap"
            @click="refreshClick"
          >
            <i :class="`fas ${elements.querySearch !=='' ? 'fa-times' : 'fa-sync-alt'}`"></i>
            <span
              class="d-none d-md-inline-block"
            >&nbsp;{{elements.querySearch !=='' ? 'Clear' : 'Refresh'}}</span>
          </button>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-primary btn-sm mr-2 text-nowrap"
            @click="toogleFilter = !toogleFilter;"
          >
            <i class="fas fa-filter"></i>
            <span class="d-none d-md-inline-block">Filter</span>
          </button>
        </div>
        <div class="mr-2 mt-0 d-none d-md-block" v-if="elements.data.length > 0">
          <select
            class="form-control form-control-sm"
            style="width: 6rem;"
            v-model="elements.pagingItem.pageSize"
            @change="pageClickHandle(1)"
          >
            <option value="20">20 rows/page</option>
            <option value="50">50 rows/page</option>
            <option value="100">100 rows/page</option>
          </select>
        </div>
      </div>
    </div>
    <VuePerfectScrollbar class="table-responsive p-0 flex-fill" style="height: 100px;">
      <div class="w-100">
        <div
          :key="element.id"
          v-for="element in elements.data"
          class="card p-2 w-100"
          style="border: 1px solid #dbdbdb;"
          v-bind:class="{ 'low-balance' : ($store.getters.lowBalanceUserId.includes(element.id)) }"
        >
          <div class="d-flex align-items-center">
            <div class="mr-2 mt-1 align-self-start position-relative">
              <img src="/img/avatars/male.png" class="profile-image" />
              <div
                class="position-absolute d-flex align-items-center top-1 right-1 left-1 bottom-1"
                v-if="element.status === 2"
              >
                <img src="/img/locking-account.svg" style="width: 2.5rem;" />
              </div>
            </div>
            <div class="flex-fill">
              <div>
                <a href="javascript:void(0);" @click="checkAccess('USER_VIEW_DETAIL') ? $getUserDetailInfo(element.id) : none()">
                  <strong class="text-primary">{{element.fullName}}</strong>
                  ({{element.userName}})
                </a>
                <button
                  type="button"
                  class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1"
                  data-toggle="tooltip"
                  title="Click copy to clipboard"
                  @click="copyToClipboard('#userDetailSameIpCopy', element.userName)"
                  style="font-size: 10px; position: relative; top: -2px;"
                >
                  <i class="fa fa-copy text-muted"></i>
                </button>
              </div>
              <div class="text-muted">
                <small>
                  <i class="far fa-envelope text-muted"></i> Email:
                  <span class="text-muted">{{element.email}}</span>
                  <button
                    type="button"
                    class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1"
                    data-toggle="tooltip"
                    title="Click copy to clipboard"
                    @click="copyToClipboard('#userDetailSameIpCopy', element.email)"
                    style="font-size: 10px; position: relative; top: -2px;"
                  >
                    <i class="fa fa-copy text-muted"></i>
                  </button>
                </small>
                <small>
                  &nbsp;|
                  <i class="fas fa-phone-square text-muted"></i> Phone:
                  <span class="text-muted">{{element.phone}}</span>
                  <button
                    type="button"
                    class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1"
                    data-toggle="tooltip"
                    title="Click copy to clipboard"
                    @click="copyToClipboard('#userDetailSameIpCopy', element.phone)"
                    style="font-size: 10px; position: relative; top: -2px;"
                  >
                    <i class="fa fa-copy text-muted"></i>
                  </button>
                </small>
              </div>
              <div class="text-muted">
                <small>
                  <i>
                    <strong
                      v-bind:class="{ 'text-danger' : (element.deviceResolution === deviceResolution), 'text-primary' : (element.deviceResolution !== deviceResolution) }"
                    >{{element.deviceResolution}}</strong>
                  </i>
                </small>
                |
                <small>
                  <i>
                    <strong
                      v-bind:class="{ 'text-danger' : (element.browserDetail.userAgent === registerBrowserDetail.userAgent), 'text-primary' : (element.browserDetail.userAgent !== registerBrowserDetail.userAgent) }"
                    >{{element.browserDetail.userAgent}}</strong>
                  </i>
                </small>
              </div>
            </div>
            <div class="d-flex flex-column">
              <h5
                class="mb-2 w-100 label label-warning"
                v-if="element.deviceResolution === deviceResolution && element.browserDetail.userAgent === registerBrowserDetail.userAgent"
              >
                <strong>Warning</strong>
              </h5>
              <h5 class="mb-0 w-100 label label-danger" v-if="element.deleted === 1">
                <strong class>Deleted</strong>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>
    <div
      class="mt-2 mr-1 d-flex align-content-center justify-content-center align-items-center"
      v-if="elements.data.length > 0"
    >
      <div class="flex-fill pl-0">
        <h4>{{elements.pagingItem.outRowsNumber}} {{elements.pagingItem.outRowsNumber > 1 ? "items" : "item"}}</h4>
      </div>
      <div class="mr-2 d-flex align-content-center justify-content-center align-items-center">
        <paginate
          v-model="elements.pagingItem.pageIndex"
          :page-count="elements.pagingItem.numberOfPage"
          :page-range="5"
          :click-handler="pageClickHandle"
          :prev-text="'Prev'"
          :next-text="'Next'"
          container-class="pagination pagination-sm"
          page-class="paginate_button page-item"
          prev-class="paginate_button page-item"
          next-class="paginate_button page-item"
          page-link-class="page-link"
          prev-link-class="page-link"
          next-link-class="page-link"
        ></paginate>
      </div>
    </div>
  </div>
</template>

<script>
import usersApi from "@/api/common/users";
import commonAct from "@/commonActionHandle.js";
import baseComponent from "@/scripts/baseComponent";
import { mapState } from "vuex";
export default {
  extends: baseComponent,
  props: {
    detailUserItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      registerBrowserDetail: {},
      deviceResolution: "",
      elements: {
        userSourceFilterList: [],
        querySearch: "",
        data: [],
        pagingItem: {
          pageIndex: 1,
          pageSize: 20,
          numberOfPage: 1,
          outRowsNumber: 0,
          orderBy: "created_at",
          directionSort: "desc",
        },
      },
    };
  },
  watch: {
    detailUserItem(newVal) {
      if (this.detailUserItem !== null) this.getListUserSameIp(1);
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.user,
      rolesInfo: (state) => state.auth.roles,
      staffsInfo: (state) => state.staffs,
      lowBalanceUser: (state) => state.lowBalanceUser,
    }),
    defaultPagingItem() {
      return {
        pageIndex: 1,
        pageSize: 20,
        numberOfPage: 1,
        outRowsNumber: 0,
        orderBy: "created_at",
        directionSort: "desc",
      };
    },
    requestParam() {
      return {
        querySearch: this.elements.querySearch,
        pageSize: this.elements.pagingItem.pageSize,
      };
    },
  },
  mounted() {
    this.getListUserSameIp(1);
  },
  methods: {
    getListUserSameIp(pageNumber) {
      this.showLoading();
      usersApi
        .getListUserSameIp(
          this.detailUserItem.id,
          pageNumber,
          this.requestParam
        )
        .then((response) => {
          if (
            response.data &&
            response.data.result === 0 &&
            response.data.data !== null
          ) {
            this.elements.data = response.data.data.data;
            this.elements.pagingItem = response.data.data.pagingItem;
            console.log(
              "getListUserSameIp -> response.data.data.targetUser.deviceInformation",
              response.data.data
            );
            this.registerBrowserDetail = JSON.parse(
              response.data.data.targetUser.deviceInformation
            ).browserDetails;
            this.deviceResolution = JSON.parse(
              response.data.data.targetUser.deviceInformation
            ).resolutionDevice;
            this.hideLoading();
          } else {
            this.elements.data = [];
            this.elements.pagingItem = this.defaultPagingItem;
            this.registerBrowserDetail = null;
            this.deviceResolution = null;
            this.hideLoading();
            if (
              response.data &&
              response.data.message !== null &&
              response.data.message !== ""
            ) {
              commonAct.showError(
                response.data.message || this.$lang.common.error
              );
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.elements.data = [];
          this.elements.pagingItem = this.defaultPagingItem;
          this.registerBrowserDetail = null;
          this.deviceResolution = null;
          this.hideLoading();
          commonAct.showError(error);
        });
    },
    getAllResult(pageNumber) {
      this.getListUserSameIp(pageNumber);
    },
    getSearchQueryResult(pageNumber) {
      this.getListUserSameIp(pageNumber);
    },
    pageClickHandle(pageNumber) {
      this.getSearchQueryResult(pageNumber);
    },
    refreshClick() {
      this.elements.querySearch = "";
      this.getAllResult(1);
    },
    onSearchInput(isDebounce) {
      if (isDebounce) {
        if (this.debounceFnc) this.debounceFnc();
      } else {
        this.getSearchQueryResult(1);
      }
    },
  },
};
</script>